import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="navbar">
      <Link to="/home" className="nav-title roman">
        Jarno De Paemeleere
      </Link>
      <div className="nav-menu-icon" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </div>
      <div className={`nav-popup ${isOpen ? "open" : ""}`}>
        <ul className="roman">
          <li>
            <Link onClick={toggleMenu} to="/project/1">
              The ropes remain still
            </Link>
          </li>
          {/** <li>
            <Link onClick={toggleMenu} to="/project/2">
              'little' America
            </Link>
          </li>*/}
          <li>
            <Link onClick={toggleMenu} to="/project/3">
              STATE OF BEING
            </Link>
          </li>
          <li>
            <Link onClick={toggleMenu} to="/project/4">
              Red Box
            </Link>
          </li>
          <li>
            <Link onClick={toggleMenu} to="/about">
              About
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
